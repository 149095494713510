table tr th {
  background: #665af5 !important;
  color: white !important;
}

table tr th,
table tr td {
  /* border: 1px solid rgba(200, 200, 200, 0.5); */
}

/* CUSTOM NOTIFICATION */

.ant-notification-notice-with-icon svg {
  fill: #fff !important;
}
.ant-notification-notice-success {
  background-color: #52c41a;
}
.ant-notification-notice-info {
  background-color: #1890ff;
}
.ant-notification-notice-error {
  background-color: #ff4d4f;
}
.ant-notification-notice-warning {
  background-color: #faad15;
}
.ant-notification-notice-success,
.ant-notification-notice-info,
.ant-notification-notice-error,
.ant-notification-notice-warning {
  border-radius: 20px;
}
.ant-notification-notice-message {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}
.ant-notification-notice-description {
  color: #eee;
}

/* TO PRINT DIAGNOSIS */
.tablePrint tr td {
  padding: 0;
  font-size: 0.9rem;
  border: 1px solid #989898;
}

.ant-select-selector {
  border-radius: 8px !important;
  font-family: 'Poppins', sans-serif !important;
  color: #637391 !important;
}

/* ANIMATION */
.shake-sunat {
  animation: bellshake 1s infinite;
}

@keyframes bellshake {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(4deg);
  }
  60% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(2deg);
  }
  85% {
    transform: rotate(-2deg);
  }
  92% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
}

/* CUSTOM HOVER FAB (MUI) */
.fab-customHover:hover {
  transition: background 0.3s ease-in-out;
  background: #fff !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.MuiTooltip-tooltip {
  background-color: #5547f7;
  color: #fff;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: normal;
  box-shadow: 7px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
}

.select-company .ant-select-selector {
  min-height: 50px !important;
  border: 1px solid #637381 !important;
  border-radius: 10px !important;
}

.select-company .ant-select-arrow {
  color: #637381 !important;
  font-weight: bolder;
}

.select-company .ant-select-selector .ant-select-selection-item div,
.select-company .ant-select-selector .ant-select-selection-search input {
  height: 100% !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #616161;
}

.fc-toolbar-title {
  text-transform: uppercase;
  color: #fff;
  padding: 5px 25px;
  background: #665af5;
  border-radius: 10px;
}

.fc-col-header-cell-cushion {
  color: #fff;
  text-transform: uppercase;
  padding: 5px 0 !important;
}

.fc-col-header-cell-cushion:hover {
  color: #fff;
}

.fc-button-primary {
  background-color: #665af5 !important;
  border: 2px solid transparent !important;
  transition: opacity, border 0.3s ease-in-out !important;
}
.fc-button-primary:hover {
  opacity: 0.8;
  background-color: #665af5 !important;
  border: 2px solid #ccc !important;
}

.fc-today-button {
  font-weight: bold !important;
}

.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button {
  padding: 10px !important;
  text-transform: uppercase;
  font-weight: bold !important;
}
